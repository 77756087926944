import Vue from 'vue'
import moment from 'moment'
import axios from 'axios'
import 'moment/src/locale/es'
import './plugins/globalComponents'
import './plugins/sentry'
import App from './App.vue'
import router from './router'
import store from './store/'
import './registerServiceWorker'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import vuetify from './plugins/vuetify'
import { cleanJSON } from '@/utilidades'
import '@mdi/font/css/materialdesignicons.css'
import VueHtmlToPaper from 'vue-html-to-paper'
import fullscreen from 'vue-fullscreen'
import titlebar from '@wuild/vue-titlebar'

moment.locale('es')

Vue.use(fullscreen)

const VueTitlebar = require('@wuild/vue-titlebar')
Vue.use(VueTitlebar)

const options = {
  name: '_blank',
  specs: [scrollbars, fullscreen, titlebar],
}

Vue.use(VueHtmlToPaper, options)

axios.interceptors.request.use(
  (config) => {
    if (['post', 'put', 'patch'].includes(config.method)) {
      config.data = cleanJSON(config.data)
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

router.beforeEach((to, from, next) => {
  if (['/', '/ru'].includes(to.path)) next('/bienvenido')
  else if (
    ['/bienvenido', '/login', '/ru/listados', '/cuenta'].includes(to.path)
  )
    next()
  else if (
    (to.path.includes('reportes') ||
      to.path.includes('consulta') ||
      to.path.includes('formularioconsul') ||
      to.path.includes('coagroindustrias') ||
      to.path.includes('coagroindustria') ||
      to.path.includes('coacuiculturas') ||
      to.path.includes('coacuicultura') ||
      to.path.includes('copecuarios') ||
      to.path.includes('copecuario') ||
      to.path.includes('cocosechas') ||
      to.path.includes('cocosecha') ||
      to.path.includes('cocultivos') ||
      to.path.includes('cocultivo') ||
      to.path.includes('conexpediente') ||
      to.path.includes('formularioconexpe') ||
      to.path.includes('coparcelas') ||
      to.path.includes('coparcela') ||
      to.path.includes('copreparaciones') ||
      to.path.includes('copreparacion') ||
      !to.path.includes('coagroturismos') ||
      !to.path.includes('coagroturismo') ||
      !to.path.includes('coartesanias') ||
      !to.path.includes('coartesania') ||
      !to.path.includes('coapiculturas') ||
      !to.path.includes('coapicultura') ||
      to.path.includes('cofincas') ||
      to.path.includes('cofinca')) &&
    store.getters['auth/userCan'](to.path).read
  )
    next()
  else if (
    (!to.path.includes('reportes') ||
      !to.path.includes('consulta') ||
      !to.path.includes('formularioconsul') ||
      !to.path.includes('coagroindustrias') ||
      !to.path.includes('coagroindustria') ||
      !to.path.includes('coacuiculturas') ||
      !to.path.includes('coacuicultura') ||
      !to.path.includes('copecuarios') ||
      !to.path.includes('copecuario') ||
      !to.path.includes('cocosechas') ||
      !to.path.includes('cocosecha') ||
      !to.path.includes('cocultivos') ||
      !to.path.includes('cocultivo') ||
      !to.path.includes('conexpediente') ||
      !to.path.includes('formularioconexpe') ||
      !to.path.includes('coparcelas') ||
      !to.path.includes('coparcela') ||
      !to.path.includes('copreparaciones') ||
      !to.path.includes('copreparacion') ||
      !to.path.includes('coagroturismos') ||
      !to.path.includes('coagroturismo') ||
      !to.path.includes('coartesanias') ||
      !to.path.includes('coartesania') ||
      !to.path.includes('coapiculturas') ||
      !to.path.includes('coapicultura') ||
      !to.path.includes('cofinca') ||
      !to.path.includes('cofincas')) &&
    store.getters['auth/userCan'](to.path).browse
  )
    next()
  else {
    store.commit('ui/setSnack', {
      text: 'No tiene permiso para acceder a este módulo.',
      color: 'error',
    })

    next(false)
  }
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
