<template>
  <v-container>
    <v-form ref="form" v-model="validado">
      <v-row dense>
        <v-col cols="12">
          <div class="text-h6">Certificaciones del Productor</div>
        </v-col>
        <!-- Fecha de actualización desde -->
        <v-col cols="4" md="4">
          <n-date
            :date.sync="filtr.from"
            label="Fecha Producción Desde"
            dense
            outlined
            name="fecha-desde"
          />
        </v-col>
        <v-col cols="4" md="4">
          <n-date :date.sync="filtr.to" label="Fecha Hasta" dense outlined />
        </v-col>
        <!-- Módulos de Producción -->
        <v-col cols="12" md="3">
          <v-select
            v-model="filtr.modulo"
            :items="productionModules"
            label="Categoría"
            outlined
            dense
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="anio_agricola"
            placeholder="####-####"
            :rules="[r.inta]"
            label="inserte el año agricola"
          >
          </v-text-field>
        </v-col>
        <!-- Finca -->
        <v-col cols="6" md="3">
          <v-autocomplete
            v-model="filtr.id_localizacion"
            label="Finca"
            :items="localizacion"
            item-text="nombre"
            item-value="id"
            outlined
            dense
            clearable
            multiple
            name="rubro"
          >
            <template v-slot:item="{ item }">
              <div>
                <div class="text-body-2 font-weight-medium">
                  {{ item.nombre || 'Sin nombre...' }}
                </div>
                <div class="text-caption">{{ item.id_productor }}</div>
              </div>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12">
          <button
            depressed
            block
            append-icon="mdi-magnify"
            outlined
            @click="certifitotales"
          >
            Generar
            <v-icon left>mdi-magnify</v-icon>
          </button>
        </v-col>
        <v-text-field :value="user.name" label="Usuario" />
        <v-text-field :value="Agenci" label="Agencia" />
      </v-row>
    </v-form>
    <v-col cols="12">
      <v-col cols="12">
        <v-card>
          <v-col cols="12">
            <div>CERTIFICADO MEF</div>
            <button
              :loading="loading_search"
              depressed
              block
              append-icon="mdi-magnify"
              outlined
              @click="ver = 4"
            >
              Ver
              <v-icon left>mdi-magnify</v-icon>
            </button>
            <button
              :loading="loading_search"
              depressed
              block
              append-icon="mdi-close"
              outlined
              @click="ver = 0"
            >
              Cerrar
              <v-icon left>mdi-close</v-icon>
            </button>
          </v-col>
        </v-card>
        <div>
          <v-col cols="12"><div></div></v-col>
          <!-- SOURCE -->
          <div v-show="ver4 == ver">
            <v-card>
              <div>
                <v-img src="img/icons/LOGO.jpg" height="150" contain></v-img>
                <h3>MINISTERIO DE DESARROLLO AGROPECUARIO</h3>
                <h3>AGENCIA {{ Agenci }}</h3>
                <h3>CERTIFICACIÓN N° {{ secuencia }}</h3>
                <br />

                <v-col cols="12">
                  <p>
                    SEÑORES:<br />MINISTERIO DE ECONOMÍA Y FINANZAS<br />Ciudad
                  </p>
                </v-col>
                <v-col cols="12">
                  <p align="12">
                    A solicitud de la parte interesada y en virtud de lo que
                    establece el numeral nueve (9) del articulo 32 de la Ley 6
                    del 2005, mediante la cual de modifico el arículo 764 del
                    Código Fiscal.
                  </p>
                </v-col>
                <h3>
                  EL SUSCRITO, DIRECTOR REGIONAL EN TODAS SUS FACULTADES
                  LEGALES, Y A PETICIÓN DE LA PARTE INTERESADA
                </h3>

                <v-col cols="12">
                  <h3 align="justify">HACE CONSTAR QUE LA:</h3>
                </v-col>
                <v-col cols="12">
                  <p align="justify">
                    &emsp;&emsp;FINCA#&emsp;&emsp;&emsp;&emsp;CODICO DE
                    UBICACIÓN&emsp;&emsp;&emsp;&emsp;DIRECCIÓN&emsp;&emsp;&emsp;&emsp;VALOR
                    CATASTRAL
                  </p>
                </v-col>
                <v-col cols="12">
                  <p align="justify"></p>
                </v-col>
                <br />
                <v-col cols="12">
                  <p align="justify">
                    Se encuentra debidamente inscrita en la Sección de la
                    Propiedad del Registro Público, Provincia de Panamá, a
                    nombre de: {{ productor.primer_nombre }}
                    {{ productor.primer_apellido }}, portador de la cédula de
                    identificacón personal N° {{ productor.id }}. Que de
                    conformidad con nuestros registro y de acuerda a las
                    inspecciones realizadas por el técnico de nuestro
                    Ministerio, la cual adjuntamos a la presente certificación,
                    esta finca se dedica a la actividad Agropecuaria desde 15 de
                    marzo de 2021, según las constumbres de producción de la
                    región y los programas de regionalización de la producción
                    del Ministerio de Desarollo Agropecuario.<br /><br />
                    Esta certificación tiene una vigencia de seis meses hábiles
                    contados a partir de su emisión, de modo que para su
                    utilización por el interesado para sus trámites debe ser
                    dentro del término indicado.<br /><br />
                    Dado en la ciudad de Panamá, a los {{ dia }} días del mes de
                    {{ mes }} de {{ anio }}
                  </p>
                  <br />
                  <br />
                  <h3>____________________________________</h3>

                  <br />
                  <br />
                  <br />
                </v-col>
                <center>
                  <img
                    src="img/icons/LOGOabajo.jpg"
                    alt="HTML5 Icon"
                    height="69"
                  />
                </center>
              </div>
            </v-card>

            <div v-show="imp" id="printMe4">
              &emsp;&emsp;&emsp;&emsp; &emsp;&emsp;&emsp;&emsp; &emsp;
              &emsp;&emsp;&emsp;&emsp;<img
                src="img/icons/LOGO.jpg"
                alt="HTML5 Icon"
                height="150"
              />
              <h3>
                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; MINISTERIO DE
                DESARROLLO AGROPECUARIO
              </h3>
              <h3>
                &emsp;&emsp;&emsp;&emsp; &emsp;&emsp;&emsp;&emsp;
                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;AGENCIA {{ Agenci }}
              </h3>
              <h3>
                &emsp;&emsp;&emsp;&emsp; &emsp;&emsp;&emsp;&emsp;
                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;CERTIFICACIÓN N°
                {{ secuencia }}
              </h3>
              <br />

              <v-col cols="12">
                <p>
                  SEÑORES:<br />MINISTERIO DE ECONOMÍA Y FINANZAS<br />Ciudad
                </p>

                <br />
                <br />
                <br />
              </v-col>
              <v-col cols="12">
                <p align="12">
                  A solicitud de la parte interesada y en virtud de lo que
                  establece el numeral nueve (9) del articulo 32 de la Ley 6 del
                  2005, mediante la cual de modifico el arículo 764 del Código
                  Fiscal.
                </p>
              </v-col>
              <h3>
                EL SUSCRITO, DIRECTOR REGIONAL EN TODAS SUS FACULTADES LEGALES,
                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;Y A
                PETICIÓN DE LA PARTE INTERESADA
              </h3>

              <v-col cols="12">
                <h3 align="justify">
                  &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;HACE
                  HACE CONSTAR QUE LA:
                </h3>
              </v-col>

              <v-col cols="12">
                <p align="justify">
                  &emsp;&emsp;FINCA#&emsp;&emsp;&emsp;&emsp;CODICO DE
                  UBICACIÓN&emsp;&emsp;&emsp;&emsp;DIRECCIÓN&emsp;&emsp;&emsp;&emsp;VALOR
                  CATASTRAL
                </p>
              </v-col>
              <br />
              <v-col cols="12">
                <p align="justify">
                  Se encuentra debidamente inscrita en la Sección de la
                  Propiedad del Registro Público, Provincia de Panamá, a nombre
                  de: {{ productor.primer_nombre }}
                  {{ productor.primer_apellido }}, portador de la cédula de
                  identificacón personal N° {{ productor.id }}. Que de
                  conformidad con nuestros registro y de acuerda a las
                  inspecciones realizadas por el técnico de nuestro Ministerio,
                  la cual adjuntamos a la presente certificación, esta finca se
                  dedica a la actividad Agropecuaria desde
                  _____________________, según las constumbres de producción de
                  la región y los programas de regionalización de la producción
                  del Ministerio de Desarollo Agropecuario.<br /><br />
                  Esta certificación tiene una vigencia de seis meses hábiles
                  contados a partir de su emisión, de modo que para su
                  utilización por el interesado para sus trámites debe ser
                  dentro del término indicado.<br /><br />
                  Dado en la ciudad de Panamá, a los {{ dia }} días del mes de
                  {{ mes }} de {{ anio }}
                </p>
                <br />
                <br />
                <h3>
                  &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;____________________________________
                </h3>

                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
              </v-col>
              <br />
              <br />
              <br />

              <img src="img/icons/LOGOabajo.jpg" alt="HTML5 Icon" height="69" />
            </div>

            <!-- OUTPUT --><v-btn
              depressed
              outlined
              color="indigo"
              class="ma-2"
              @click="print4"
            >
              Imprimir
            </v-btn>
          </div>
        </div>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-col cols="12">
            <div>FORMULARIOS DE INSPECCIÓN PARA CERTIFICACIONES</div>
            <button
              :loading="loading_search"
              depressed
              block
              append-icon="mdi-magnify"
              outlined
              @click="ver = 1"
            >
              Ver
              <v-icon left>mdi-magnify</v-icon>
            </button>
            <button
              :loading="loading_search"
              depressed
              block
              append-icon="mdi-close"
              outlined
              @click="ver = 0"
            >
              Cerrar
              <v-icon left>mdi-close</v-icon>
            </button>
          </v-col>
        </v-card>
        <div>
          <v-col cols="12"><div></div></v-col>
          <!-- SOURCE -->
          <div v-show="ver1 == ver">
            <v-card>
              <div>
                <v-img src="img/icons/LOGO.jpg" height="150" contain></v-img>
                <h3>MINISTERIO DE DESARROLLO AGROPECUARIO</h3>
                <br />
                <br />
                <v-col cols="12">
                  <p align="justify">
                    N° de inspección: {{ secuencia }} Agencia MIDA de
                    {{ Agenci }} <br />
                    Yo, {{ user.name }}, con cédula de identidad personal número
                    ________________ , Extensionista o Médico Veterinario de la
                    Agencia de {{ Agenci }}, certifico que he realizado
                    inspección o he atendido al Sr.
                    {{ productor.primer_nombre }}
                    {{ productor.primer_apellido }}, con cédula de identidad
                    personal número {{ productor.id }}, el mismo se encuentra
                    registrado como productor {{ filtr.modulo }} en el(los)
                    rubro(s) {{ Rubs }} y se encuentra debidamente registrado en
                    el Sistema Integrado de Gestión Agropecuaria (SIGAP). Dado
                    en la ciudad de Panamá a los {{ dia }} días del mes de
                    {{ mes }} del año {{ anio }}.
                  </p>

                  <br />
                  <br />
                  <br />
                  <p align="justify">Atentamente,</p>
                  <h3>_____________________________</h3>
                  <i><h3>Firma del Extensionista o</h3></i>
                  <i><h3>Médico Veterinario</h3></i>
                  <h3>___________________</h3>

                  <i><h3>Idoneidad</h3></i>
                  <p>Copias: archivo</p>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </v-col>
              </div>
            </v-card>

            <div v-show="imp" id="printMe1">
              &emsp;&emsp;&emsp;&emsp; &emsp;&emsp;&emsp;&emsp; &emsp;
              &emsp;&emsp;&emsp;&emsp;<img
                src="img/icons/LOGO.jpg"
                alt="HTML5 Icon"
                height="150"
              />
              <h3>
                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; MINISTERIO DE
                DESARROLLO AGROPECUARIO
              </h3>
              <br />
              <br />
              <p align="justify">
                N° de inspección: ________________ Agencia MIDA de
                {{ Agenci }} <br />
                Yo, {{ user.name }}, con cédula de identidad personal número
                ________________ , Extensionista o Médico Veterinario de la
                Agencia de {{ Agenci }}, certifico que he realizado inspección o
                he atendido al Sr.
                {{ productor.primer_nombre }}
                {{ productor.primer_apellido }}, con cédula de identidad
                personal número {{ productor.id }}, el mismo se encuentra
                registrado como productor {{ filtr.modulo }} en el(los) rubro(s)
                {{ Rubs }} y se encuentra debidamente registrado en el Sistema
                Integrado de Gestión Agropecuaria (SIGAP). Dado en la ciudad de
                Panamá a los {{ dia }} días del mes de {{ mes }} del año
                {{ anio }}.
              </p>

              <br />
              <br />
              <br />
              <p>Atentamente,</p>
              <h2 text-align:center>
                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;_____________________________
              </h2>
              <i
                ><h2>
                  &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;Firma
                  del Extensionista o
                </h2></i
              >
              <i
                ><h2>
                  &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;Médico
                  Veterinario
                </h2></i
              >
              <h2>
                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp;___________________
              </h2>

              <i
                ><h2>
                  &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;Idoneidad
                </h2></i
              >
              <p>Copias: archivo</p>
            </div>

            <!-- OUTPUT --><v-btn
              depressed
              outlined
              color="indigo"
              class="ma-2"
              @click="print1"
            >
              Imprimir
            </v-btn>
          </div>
        </div>
      </v-col>
      <!----------------------------------------------------------------------------------------------------------------- -->
      <v-col cols="12">
        <v-card>
          <v-col cols="12">
            <div>Modelos de Certificaciones JEFE DE AGENCIA PAGINA #1</div>
            <button
              :loading="loading_search"
              depressed
              block
              append-icon="mdi-magnify"
              outlined
              @click="ver = 2"
            >
              Ver
              <v-icon left>mdi-magnify</v-icon>
            </button>
            <button
              :loading="loading_search"
              depressed
              block
              append-icon="mdi-close"
              outlined
              @click="ver = 0"
            >
              Cerrar
              <v-icon left>mdi-close</v-icon>
            </button>
          </v-col>
        </v-card>
        <div>
          <v-col cols="12"><div></div></v-col>
          <!-- SOURCE -->
          <div v-show="ver2 == ver">
            <v-card>
              <div>
                <v-img src="img/icons/LOGO.jpg" height="150" contain></v-img>
                <h3>DIRECCIÓN EJECUTIVA REGIONAL</h3>
                <h3>R{{ Region }}, {{ Regional }}</h3>
                <h3>AGENCIA</h3>
                <h3>{{ Agenci }}</h3>
                <br />
                <br />
                <v-col cols="12">
                  <p>Fecha: {{ dia }} de {{ mes }} de {{ anio }}</p>
                  <br />
                  <br />
                  <p align="justify">
                    Por este medio Yo, {{ user.name }}, con cédula de identidad
                    personal _____________ e idoneidad___________, en mi
                    condición de Jefe de Agencia, certifico que el productor
                    {{ productor.primer_nombre }}
                    {{ productor.primer_apellido }}, con cédula
                    {{ productor.id }}
                    {{ Datos }}
                  </p>
                </v-col>

                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <p>
                  __________________________ &emsp; &emsp;
                  &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;____________________
                  &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                  &emsp;&emsp;_____________
                </p>
                <p>
                  Nombre del Jefe de Agencia &emsp; &emsp; &emsp;
                  &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;Firma &emsp;
                  &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;Sello
                  Idoneidad
                </p>
                <br />
                <br />
                <br />
                <br />
                <center>
                  <img
                    src="img/icons/LOGOabajo.jpg"
                    alt="HTML5 Icon"
                    height="69"
                  />
                </center>
              </div>
            </v-card>
            <v-card>
              <div v-show="imp" id="printMe2">
                &emsp;&emsp;&emsp;&emsp; &emsp;&emsp;&emsp;&emsp; &emsp;
                &emsp;&emsp;&emsp;&emsp;<img
                  src="img/icons/LOGO.jpg"
                  alt="HTML5 Icon"
                  height="150"
                />
                <h3>
                  &emsp;&emsp;&emsp;&emsp; &emsp;&emsp;&emsp;&emsp;
                  &emsp;&emsp;&emsp;DIRECCIÓN EJECUTIVA REGIONAL
                </h3>
                <h3>
                  &emsp;&emsp;&emsp;&emsp; &emsp;&emsp;&emsp;&emsp;
                  &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;R{{ Region }},
                  {{ Regional }}
                </h3>
                <h3>
                  &emsp;&emsp;&emsp;&emsp; &emsp;&emsp;&emsp;&emsp;
                  &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;AGENCIA
                </h3>
                <h3>
                  &emsp;&emsp;&emsp;&emsp; &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                  &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;{{ Agenci }}
                </h3>
                <br />
                <br />
                <p>Fecha: {{ dia }} de {{ mes }} de {{ anio }}</p>
                <br />
                <br />
                <v-col cols="12">
                  <p align="justify">
                    Por este medio Yo, {{ user.name }}, con cédula de identidad
                    personal _____________ e idoneidad___________, en mi
                    condición de Jefe de Agencia, certifico que el productor
                    {{ productor.primer_nombre }}
                    {{ productor.primer_apellido }}, con cédula
                    {{ productor.id }}
                    {{ Datos }}
                  </p>
                </v-col>

                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <p>
                  __________________________ &emsp; &emsp;
                  &emsp;&emsp;&emsp;&emsp;____________________
                  &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp;&nbsp;_____________
                </p>
                <p>
                  Nombre del Jefe de Agencia &emsp; &emsp; &emsp;
                  &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp;Firma
                  &emsp;
                  &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;Sello
                  Idoneidad
                </p>
                <br />
                <br />
                <br />
                <br />
                <img
                  src="img/icons/LOGOabajo.jpg"
                  alt="HTML5 Icon"
                  height="69"
                />
              </div>
            </v-card>

            <!-- OUTPUT --><v-btn
              depressed
              outlined
              color="indigo"
              class="ma-2"
              @click="print2"
            >
              Imprimir
            </v-btn>
          </div>
        </div>
      </v-col>

      <v-col cols="12">
        <v-card>
          <v-col cols="12">
            <div>Modelos de Certificaciones DIRECTOR REGIONAL PAGINA #2</div>
            <button
              :loading="loading_search"
              depressed
              block
              append-icon="mdi-magnify"
              outlined
              @click="ver = 3"
            >
              Ver
              <v-icon left>mdi-magnify</v-icon>
            </button>
            <button
              :loading="loading_search"
              depressed
              block
              append-icon="mdi-close"
              outlined
              @click="ver = 0"
            >
              Cerrar
              <v-icon left>mdi-close</v-icon>
            </button>
          </v-col>
        </v-card>
        <div>
          <v-col cols="12"><div></div></v-col>
          <!-- SOURCE -->
          <div v-show="ver3 == ver">
            <v-card>
              <div>
                <v-img src="img/icons/LOGO.jpg" height="150" contain></v-img>
                <h3>DIRECCIÓN EJECUTIVA REGIONAL</h3>
                <h3>R{{ Region }}, {{ Regional }}</h3>
                <br />
                <br />
                <br />
                <br />
                <v-col cols="12">
                  <p>Fecha: {{ dia }} de {{ mes }} de {{ anio }}</p>
                  <br />
                  <br />
                  <p align="justify">
                    Por este medio Yo _____________, con cédula de identidad
                    personal __________ e idoneidad _________, Director
                    Ejecutivo Regional, doy fe y veracidad de la certificación
                    emitida por el Jefe de Agencia {{ user.name }}, con cédula
                    _________ de la Agencia de {{ Agenci }}. Esta certificación
                    corresponde al productor
                    {{ productor.primer_nombre }}
                    {{ productor.primer_apellido }} con cédula
                    {{ productor.id }}.
                  </p>
                </v-col>

                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <p>
                  __________________________ &emsp; &emsp;
                  &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;____________________
                  &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                  &emsp;&emsp;_____________
                </p>
                <p>
                  Nombre del Jefe de Agencia &emsp; &emsp; &emsp;
                  &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;Firma &emsp;
                  &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;Sello
                  Idoneidad
                </p>
                <br />
                <br />
                <br />
                <br />
                <center>
                  <img
                    src="img/icons/LOGOabajo.jpg"
                    alt="HTML5 Icon"
                    height="69"
                  />
                </center>
              </div>
            </v-card>
            <v-card>
              <div v-show="imp" id="printMe3">
                &emsp;&emsp;&emsp;&emsp; &emsp;&emsp;&emsp;&emsp; &emsp;
                &emsp;&emsp;&emsp;&emsp;<img
                  src="img/icons/LOGO.jpg"
                  alt="HTML5 Icon"
                  height="150"
                />
                <h3>
                  &emsp;&emsp;&emsp;&emsp; &emsp;&emsp;&emsp;&emsp;
                  &emsp;&emsp;&emsp;DIRECCIÓN EJECUTIVA REGIONAL
                </h3>
                <h3>
                  &emsp;&emsp;&emsp;&emsp; &emsp;&emsp;&emsp;&emsp;
                  &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;{{ Region }},
                  {{ Regional }}
                </h3>
                <h3>
                  &emsp;&emsp;&emsp;&emsp; &emsp;&emsp;&emsp;&emsp;
                  &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;AGENCIA
                </h3>
                <h3>
                  &emsp;&emsp;&emsp;&emsp; &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                  &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;{{ Agenci }}
                </h3>
                <br />
                <br />
                <p>Fecha: {{ dia }} de {{ mes }} de {{ anio }}</p>
                <br />
                <br />
                <v-col cols="12">
                  <p align="justify">
                    Por este medio Yo, _________________, con cédula de
                    identidad personal _________ e idoneidad __________,
                    Director Ejecutivo Regional, doy fe y veracidad de la
                    certificación emitida por el Jefe de Agencia {{ Agenci }},
                    con cédula _______ de la Agencia de {{ Agenci }}. Esta
                    certificación corresponde al productor
                    {{ productor.primer_nombre }}
                    {{ productor.primer_apellido }} con cédula
                    {{ productor.id }}.
                  </p>
                </v-col>

                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <p>
                  __________________________ &emsp; &emsp;
                  &emsp;&emsp;&emsp;&emsp;____________________
                  &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp;&nbsp;_____________
                </p>
                <p>
                  Nombre del Director &emsp; &emsp; &emsp;
                  &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp;Firma
                  &emsp;
                  &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;Sello
                  Idoneidad
                </p>
                <br />
                <br />
                <br />
                <br />
                <img
                  src="img/icons/LOGOabajo.jpg"
                  alt="HTML5 Icon"
                  height="69"
                />
              </div>
            </v-card>

            <!-- OUTPUT -->
            <v-btn
              depressed
              outlined
              color="indigo"
              class="ma-2"
              @click="print3"
            >
              Imprimir
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-col>
    <registro-existente
      :title="'El productor ya existe'"
      :description="'La identificación colocada corresponde a un productor existente, desea cargar la información de este productor?'"
      :modal="modal_registro_existe"
      :loading="modal_registro_existe_loading"
      @cerrarModal="cerrarModalDeRegistroExistente"
    />
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import debounce from 'lodash.debounce'
import { telefono_persona, formulario_de_productor } from '@/modelos'
import * as rules from '@/utils/validations'
import { getCurrentPosition, clona, edad } from '@/utilidades'
import moment from 'moment'
import { clone } from '@/utilidades'
import { productionModules } from '@/utils/enums'
//import { observable } from 'vue/types/umd'

const filtrModel = Object.freeze({
  estado: ['A'],
  from: moment().subtract(31, 'days'),
  to: moment().endOf('day'),
  modulo: 'AGRICOLA',
  id_productor: '',
  id_institucion: [],
  id_destino: [],
  id_localizacion: '',
  id_user: '',
  cultivo: [],
  ciclo: '',
  familiar: '',
  tipo_familiar: '',
  id_provincia: '',
  id_distrito: '',
  id_corregimiento: '',
  id_poblado: '',
  sexo: '',
})

export default {
  name: 'FormularioConExpe',
  data: () => {
    return {
      fecha: new Date(),
      fincas: [],
      secuencia: '',
      Agenci: '',
      Region: '',
      Regional: '',
      Dato: '',
      Datos: '',
      Rubs: '',
      Rub: '',
      rubro_viejo: '',
      rubro_nuevo: '',
      objetoviejo: {
        Rubro: '',
        SUPERFICIE_ACTUAL_SEMBRADA: '',
        produccion: '',
        superficie_cosechada: '',
      },
      arreglo: [],
      papa: {},
      anio_agricola: '',
      dia: '',
      meses: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
      ],
      anio: '',
      mes: '',
      checkbox1: '',
      checkbox2: '',
      url: 'img/icons/LOGO.jpg',
      imp: false,
      output: null,
      ver: 0,
      ver1: 1,
      ver2: 2,
      ver3: 3,
      ver4: 4,
      tipo_personita: '',
      tipo_identif: '',
      estadito: '',
      sino_leer: '',
      sino_escribir: '',
      susto: {},
      r: rules,
      validado: false,
      modal_registro_existe: false,
      modal_registro_existe_loading: false,
      tiene_empresa: false,
      registro_existente: {},
      productor: clona(formulario_de_productor),
      loading: false,
      loading_search: false,
      detailActionName: 'reportes/produccionDetallada2',
      filtrModel: clone(filtrModel),
      filtr: clone(filtrModel),
      productionModules,
      detail: {
        items: [],
        headers: [],
        total: 0,
        page: 1,
        itemsPerPage: 50,
        perPage: 50,
      },
    }
  },
  computed: {
    localizacion() {
      switch (this.filtr.modulo) {
        case 'PESCA':
          return this.fincas
        default:
          return this.fincas
      }
    },
    // retorna cierto si existe al menos un número en el arreglo de numeros del productor
    puedeRemoverNumero() {
      return this.productor.numeros.length >= 0
    },
    edad() {
      return edad(this.productor.fecha_nacimiento)
    },
    puedeGuardar() {
      return (
        this.obtenerIdDeRuta() === '' ||
        this.obtenerIdDeRuta() === this.productor.id
      )
    },
    ...mapState('listados', [
      'sino',
      'tipo_empresa',
      'pais',
      'sexo',
      'tipo_telefono',
      'organizacion',
      'escolaridad',
      'tipo_organizacion',
      'tipo_identificacion',
      'institucion',
      'sistema_gestion_calidad',
      'actividad',
      'estado',
    ]),
    ...mapGetters('listados', [
      'provincias',
      'distritos',
      'corregimientos',
      'poblados',
      'provinciaPorId',
      'distritoPorId',
      'corregimientoPorId',
      'pobladoPorId',
      'sexoPorId',
      'tipoOrganizacionPorId',
      'programaGobiernoPorId',
      'organizacionPorId',
      'agenciaPorId',
      'regionNomPorId',
      'regionalPorId',
    ]),
    ...mapGetters('registros', ['productor_actual', 'existeRegistroEnState']),
    ...mapGetters('auth', ['user']),
  },
  watch: {
    '$route.params.id'() {
      this.cargarRegistro(this.obtenerIdDeRuta())
    },
    'productor.tipo_empresa'() {
      if (this.productor.tipo_empresa === 2) {
        this.tiene_empresa = true
        this.tipo_personita = 'PERSONA JURIDICA'
      } else {
        this.tiene_empresa = false
        this.tipo_personita = 'PERSONA NATURAL'
      }
    },
    'productor.tipo_id'() {
      if (this.productor.tipo_id === 1) {
        this.tipo_identif = 'CEDULA'
      } else {
        this.tipo_identif = 'PASAPORTE'
      }
    },
    'productor.estado'() {
      if (this.productor.tipo_id === 1) {
        this.estadito = 'ACTIVO'
      } else {
        this.estadito = 'INACTIVO'
      }
    },
    'productor.sabe_leer'() {
      if (this.productor.sabe_leer === 0) {
        this.sino_leer = 'NO'
      } else {
        this.sino_leer = 'SI'
      }
    },
    'productor.sabe_escribir'() {
      if (this.productor.escribir === 0) {
        this.sino_escribir = 'NO'
      } else {
        this.sino_escribir = 'SI'
      }
    },
    'productor.id_provincia'(newVal, oldVal) {
      if (!!oldVal && newVal !== oldVal) {
        this.productor.id_distrito = ''
        this.productor.id_corregimiento = ''
        this.productor.id_poblado = ''
      }
    },
    'productor.id_distrito'(newVal, oldVal) {
      if (!!oldVal && newVal !== oldVal) {
        this.productor.id_corregimiento = ''
        this.productor.id_poblado = ''
      }
    },
    'productor.id_corregimiento'(newVal, oldVal) {
      if (!!oldVal && newVal !== oldVal) {
        this.productor.id_poblado = ''
      }
    },
    'productor.empresa.id_provincia'(newVal, oldVal) {
      if (!!oldVal && newVal !== oldVal) {
        this.productor.empresa.id_distrito = ''
        this.productor.empresa.id_corregimiento = ''
        this.productor.empresa.id_poblado = ''
      }
    },
    'productor.empresa.id_distrito'(newVal, oldVal) {
      if (!!oldVal && newVal !== oldVal) {
        this.productor.empresa.id_corregimiento = ''
        this.productor.empresa.id_poblado = ''
      }
    },
    'productor.empresa.id_corregimiento'(newVal, oldVal) {
      if (!!oldVal && newVal !== oldVal) {
        this.productor.empresa.id_poblado = ''
      }
    },
  },
  async mounted() {
    ;(this.dia = this.fecha.getDate()),
      (this.mes = this.meses[this.fecha.getMonth()]),
      (this.anio = this.fecha.getFullYear()),
      this.$store.commit('ui/updateToolbar', 'Certificaciones del Productor')
    // si un id viene como parte de la ruta, intentar cargar el productor con ese id del almacenamiento local
    const id = this.obtenerIdDeRuta()
    if (id) {
      this.$store.commit('registros/productorActual', id)
      //this.$store.commit('registros/definirTipoProductor')
      this.cargarRegistro(id)
    }
    try {
      this.fincas = (await this.$store.dispatch('registros/listarFincas')).data
    } catch (error) {
      this.fincas = []
      this.$sentry.captureException(error)
    }
  },
  methods: {
    print1(url) {
      // Pass the element id here
      this.$htmlToPaper('printMe1', url)
    },
    print2(url) {
      // Pass the element id here
      this.$htmlToPaper('printMe2', url)
    },
    print3(url) {
      // Pass the element id here
      this.$htmlToPaper('printMe3', url)
    },

    print4(url) {
      // Pass the element id here
      this.$htmlToPaper('printMe4', url)
    },
    async obtenerPosicionDeEmpresa() {
      const position = await getCurrentPosition()
      if (position) {
        this.productor.empresa.longitud = position.coords.longitude
        this.productor.empresa.latitud = position.coords.latitude
      }
    },
    async certifitotales() {
      this.Agenci = this.agenciaPorId(this.user.id_agencia)
      this.Region = this.regionalPorId(this.user.id_agencia)
      this.Regional = this.regionNomPorId(this.Region)
      //this.modulo = this.filtr.modulo
      this.loading = true
      this.detail.page = 1
      this.arreglo = []
      var secuen = this.fecha * 1
      //var secuen = new Date().getTime()
      this.secuencia =
        secuen +
        '' +
        this.Region +
        this.user.id +
        this.user.id_agencia +
        this.productor.id.substr(-1)
      this.filtr.id_productor = this.productor.id
      //alert(this.fecha)
      //detail = {}
      try {
        const opts = { includeCharts: 1, noPaginate: 0 },
          actionName = 'reportes/produccionDetallada2'
        const { items, page, total, computedData } = await this._genera(
          opts,
          actionName,
          {
            page: this.detail.page,
            perPage: this.detail.itemsPerPage,
          }
        )
        this.detail.items = items
        this.detail.page = page
        this.detail.total = total
        if (opts.includeCharts) {
          this.detail.computedData = computedData
        }
        //detail.headers = mapHeadersFromItems(detail.items, this.ignoreHeaders)
        this.rubro_viejo = ''
        let arreglo2 = []
        for (let i = 0; i < this.papa.items.length; i++) {
          let objetoviejo = {
            Rubro: 'dato no especificado',
            SUPERFICIE_ACTUAL_SEMBRADA: 0,
            produccion: 0,
            superficie_cosechada: 0,
          }
          if (this.papa.items[i].Registro === 'COSECHA') {
            this.rubro_nuevo = this.papa.items[i].Rubro
            objetoviejo.Rubro = this.papa.items[i].Rubro
            objetoviejo.SUPERFICIE_ACTUAL_SEMBRADA =
              this.papa.items[i]['Superficie sembrada (Ha)']
            objetoviejo.superficie_cosechada =
              this.papa.items[i]['Superficie cosechada (Ha)']
            objetoviejo.produccion = this.papa.items[i].Producción
            if (this.rubro_nuevo != this.rubro_viejo) {
              arreglo2.push(objetoviejo)
              this.Rub = objetoviejo.Rubro
              this.Dato =
                this.Dato +
                ' Para el ciclo agrícola ' +
                this.anio_agricola +
                ' sembró un total de '
              if (objetoviejo.SUPERFICIE_ACTUAL_SEMBRADA > 0) {
                this.Dato = this.Dato + objetoviejo.SUPERFICIE_ACTUAL_SEMBRADA
              } else {
                this.Dato = this.Dato + ' 0 '
              }
              this.Dato =
                this.Dato +
                ' ha(s) del rubro ' +
                objetoviejo.Rubro +
                ' de las cuales cosechó un total de '
              if (objetoviejo.produccion > 0) {
                this.Dato =
                  this.Dato +
                  objetoviejo.produccion +
                  ' kilogramo(s). Obteniendo un rendimiento de ' +
                  objetoviejo.produccion / objetoviejo.superficie_cosechada +
                  ' kilogramo(s) de ' +
                  objetoviejo.Rubro +
                  ' por ha(s).'
              } else {
                this.Dato = this.Dato + ' 0 ' + ' kilogramos.'
              }
              this.Datos += this.Dato
              this.Rubs += this.Rub + ', '

              /*objetoviejo.Rubro = ' '
              objetoviejo.SUPERFICIE_ACTUAL_SEMBRADA = ' '
              objetoviejo.produccion = ' '
              objetoviejo.superficie_cosechada = ' '*/
              this.Dato = ''
              this.Rub = ''
            }
            this.rubro_viejo = this.papa.items[i].Rubro
          }
          if (this.papa.items[i].Registro === 'SIEMBRA') {
            this.rubro_nuevo = this.papa.items[i].Rubro
            objetoviejo.Rubro = this.papa.items[i].Rubro
            objetoviejo.SUPERFICIE_ACTUAL_SEMBRADA =
              this.papa.items[i]['Superficie sembrada (Ha)']
            if (this.rubro_nuevo != this.rubro_viejo) {
              arreglo2.push(objetoviejo)
              this.Rub = objetoviejo.Rubro
              this.Dato =
                this.Dato +
                ' Para el ciclo agrícola ' +
                this.anio_agricola +
                ' sembró un total de '
              if (objetoviejo.SUPERFICIE_ACTUAL_SEMBRADA > 0) {
                this.Dato = this.Dato + objetoviejo.SUPERFICIE_ACTUAL_SEMBRADA
              } else {
                this.Dato = this.Dato + ' 0 '
              }
              this.Dato =
                this.Dato +
                ' ha(s) del rubro ' +
                objetoviejo.Rubro +
                ' de las cuales cosechó un total de '
              if (objetoviejo.produccion > 0) {
                this.Dato =
                  this.Dato +
                  objetoviejo.produccion +
                  ' kilogramo(s). Obteniendo un rendimiento de ' +
                  objetoviejo.produccion / objetoviejo.superficie_cosechada +
                  ' kilogramo(s) de ' +
                  objetoviejo.Rubro +
                  ' por ha(s).'
              } else {
                this.Dato = this.Dato + ' 0 ' + ' kilogramos.'
              }
              this.Datos += this.Dato
              this.Rubs += this.Rub + ', '

              /*objetoviejo.Rubro = ' '
              objetoviejo.SUPERFICIE_ACTUAL_SEMBRADA = ' '
              objetoviejo.produccion = ' '
              objetoviejo.superficie_cosechada = ' '*/
              this.Dato = ''
              this.Rub = ''
            }
            /*if (this.rubro_nuevo != this.rubro_viejo) {
              this.arreglo.push(objetoviejo)
              this.Rub = objetoviejo.Rubro
              this.Dato =
                ' Sembró un total de ' +
                objetoviejo.SUPERFICIE_ACTUAL_SEMBRADA +
                ' has del rubro ' +
                objetoviejo.Rubro +
                ' para el ciclo agrícola ' +
                this.anio_agricola +
                ' de las cuales cosechó un total de ' +
                objetoviejo.produccion +
                ' kilogramos. Obteniendo un rendimiento de ' +
                objetoviejo.produccion / objetoviejo.superficie_cosechada +
                ' kilogramos de ' +
                objetoviejo.Rubro +
                ' por has.'
              this.Datos += this.Dato
              this.Rubs += this.Rub + ', '

                /*objetoviejo.Rubro = ' '
                objetoviejo.SUPERFICIE_ACTUAL_SEMBRADA = ' '
                objetoviejo.produccion = ' '
                objetoviejo.superficie_cosechada = ' '*/
            /*this.Dato = ''
            }*/
            this.rubro_viejo = this.papa.items[i].Rubro
          }
        }
        this.arreglo = arreglo2
        /* 
   for items.length
    if registro = "SIEMBRA"    
      rubro_nuevo  = items.Rubro      
      if rubro_actual != rubro.nuevo
        rubro_actual = items.Rubro
        Arreglo.rubro[count] = items.Rubro        
        count=count + 1
      endif
      Arreglo.hasembrada[count] = Arreglo.hasembrada[count] + items.SUPERFICIE_ACTUAL_SEMBRADA
    else if registro = "COSECHA"     
      if rubro_actual != rubro.nuevo
        rubro_actual = items.Rubro
        Arreglo.rubro[count] = items.Rubro        
        count=count + 1
      endif
      Arreglo.hacosechada[count] = Arreglo.hacosechada[count] + items.superficie_cosechada
      Arreglo.produccion[count] = Arreglo.produccion[count] + items.produccion 
    endif
     */
      } catch (error) {
        this.$sentry.captureException(error)
      } finally {
        this.loading = false
      }
    },
    async _genera(
      opts = { includeCharts: 0, noPaginate: 0 },
      actionName = '',
      payload = {}
    ) {
      try {
        const response = await this.$store.dispatch(actionName, {
          ...payload,
          ...this.filtr,
          includeCharts: opts.includeCharts,
          noPaginate: opts.noPaginate,
        })
        this.papa = response
        return {
          items: response.items,
          page: response.currentPage,
          total: response.total,
          computedData: response.computedData,
        }
      } catch (error) {
        this.$sentry.captureException(error)
      }
    },

    async obtenerPosicionProductor() {
      const position = await getCurrentPosition()
      if (position) {
        this.productor.longitud = position.coords.longitude
        this.productor.latitud = position.coords.latitude
      }
    },
    // agrega un objeto de número al arreglo de números del productor
    agregarNumero() {
      this.productor.numeros.push({ ...telefono_persona })
    },
    // remueve un número del arreglo de números
    removerNumero(index) {
      if (this.puedeRemoverNumero) {
        this.productor.numeros.splice(index, 1)
      }
    },
    // registra el productor en el almacenamiento local, lo coloca como productor actual y pasa a la siguiente pantalla
    //async guardarYContinuar() {
    //try {
    //this.loading = true
    //if (!this.$refs.form.validate()) {
    //this.$store.commit('ui/setSnack', {
    //text: 'Verifique los elementos en rojo',
    //color: 'error',
    //})
    //return
    //}
    //if (await this.buscarRegistroExistente()) {
    //return
    //}
    //if (!this.obtenerIdDeRuta()) {
    //  this.productor.created_at = new Date().getTime() / 1000
    //}
    //this.productor.updated_at = new Date().getTime() / 1000

    //await this.$store.dispatch(
    //  'registros/guardarProductor',
    //  clona(this.productor)
    //)
    //} catch (error) {
    //this.$sentry.captureException(error)
    //} finally {
    //this.loading = false
    //}
    //},
    /*async cargarRegistroExistente() {
      const id = this.registro_existente.id
      this.modal_registro_existe_loading = true
      if (this.existeRegistroEnState('productoresci', id)) {
        await this.cargarRegistroDelServer(this.registro_existente)
      }
      this.$router.push(`/ru/productor/conexpediente/${id}`)
      this.cerrarModalDeRegistroExistente()
      this.modal_registro_existe_loading = false
    },*/
    cargarRegistro(id) {
      if (this.existeRegistroEnState('productoresci', id)) {
        this.productor = this.obtenerRegistroDelState(id)
        //this.cargarRegistroDelServer(id)
        return true
      } else {
        return false
      }
    },
    //async buscarRegistroExistente() {
    //  const registro = await this.obtenerUnRegistroONada()
    //if (elObjetoTienePropiedades(registro)) {
    //const id = registro.id ? registro.id.toString() : ''
    //if (id === this.obtenerIdDeRuta()) {
    //return false
    //}
    //this.almacenarRegistroEncontradoTemporalmente(registro)
    //this.mostrarModalDeRegistroExistente()
    //return true
    //}
    //return false
    //},
    debounceBuscarRegistroExistente: debounce(async function () {
      await this.buscarRegistroExistente()
    }, 1500),
    async obtenerUnRegistroONada() {
      try {
        const id = this.productor.id
        // required fields to be fulfilled
        if (id) {
          const { status, data } = await this.$store.dispatch(
            'registros/listarProductores',
            { id }
          )
          if (status === 'ok' && data.length === 1) {
            return data[0]
          }
        }
        return {}
      } catch (e) {
        return {}
      }
    },
    async cargarRegistroDelServer(registro) {
      try {
        this.susto = await this.$store.dispatch(
          'registros/getConsulta',
          registro.id
        )
      } catch (e) {
        return false
      }
    },
    obtenerIdDeRuta() {
      return this.$route.params.id || ''
    },
    obtenerRegistroDelState(id) {
      return {
        ...clona(formulario_de_productor),
        ...clona(this.$store.state.registros.productoresci[id]),
      }
    },
    almacenarRegistroEncontradoTemporalmente(registro) {
      this.registro_existente = registro
    },
    mostrarModalDeRegistroExistente() {
      this.modal_registro_existe = true
    },
    cerrarModalDeRegistroExistente() {
      this.modal_registro_existe = false
    },
  },
}
</script>
<style scoped>
img {
  height: '';
}
h4 {
  font-size: 200em;
  color: rgb(0, 0, 0);
  text-align: center;
}
h3 {
  font-size: 1em;
  color: rgb(0, 0, 0);
  text-align: center;
}
h2 {
  font-size: 1em;
  color: rgb(0, 0, 0);
}
</style>
