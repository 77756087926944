import XLSX from 'xlsx'
import axios from 'axios'
import store from './store'
import moment from 'moment'
import { isOfflineId } from '@/utils/check'
/* import { preparacion } from './modelos' */

const sumRecordsProp = (records, prop) => {
  let sum = 0
  records.forEach((record) => {
    const toSum = record[prop] ?? 0
    sum += parseFloat(toSum)
  })
  return sum
}

const isProductionDateValidComparedToRecords = (
  referenceRecord,
  compareRecords
) => {
  let comparissons = []
  for (const record of compareRecords) {
    if (record.date) {
      comparissons.push({
        record: record.record,
        status: moment(referenceRecord.date)[record.cp](record.date),
      })
    }
  }
  return (
    comparissons.find((c) => c.status === false) ?? { record: '', status: true }
  )
}

// verifica que los roles del usuario [user_roles] existan dentro de los roles permitidos [roles]
// retorna un boolean si es válido o no
const rol_permitido = (user_roles, roles) => {
  let permitido = false
  if (Array.isArray(user_roles)) {
    user_roles.every((role) => {
      if (roles.includes(role)) {
        permitido = true
      }
      return true
    })
  }
  return permitido
}
// No cambie esto, -url de la aplicación si se encuentra en producción o desarrollo
const url_base =
  process.env.NODE_ENV === 'production'
    ? '/suag/public'
    : 'http://10.253.177.68/suag/public'
//    : 'http://localhost/suag/public'
//    : 'https://sigap.mida.gob.pa/suag/public'
//  : 'http://10.253.177.70/suag/public'
//: 'http://10.253.177.70/suag/public'
//    : 'http://10.253.177.104/suag/public'
// wrapper de axios que ademas de realizar la petición http intercepta los casos sin conexión
// cuando el server response con 403 (access denied) para permitirle al usuario reautenticarse
const http = async (axios_object) => {
  if (navigator.onLine) {
    store.commit('ui/setOnlineStatus', true)
    // set token in request
    if (store.state.auth.current_user) {
      const current_user = store.state.auth.users[store.state.auth.current_user]
      const token = current_user.token
      if (token) {
        if (Object.prototype.hasOwnProperty.call(axios_object, 'data')) {
          axios_object.data = { token: token, ...axios_object.data }
        }
        if (Object.prototype.hasOwnProperty.call(axios_object, 'params')) {
          axios_object.params = { token: token, ...axios_object.params }
        }
      }
    }
    // prevent saving with offline id on required synched records
    const requiredSynchedKeys = [
      'id_finca',
      'ID_FINCA',
      'id_parcela',
      'ID_PARCELA',
      'id_establecimiento',
      'ID_ESTABLECIMIENTO',
      'id_embarcacion',
    ]
    if (Object.prototype.hasOwnProperty.call(axios_object, 'data')) {
      for (const key of Object.keys(axios_object.data)) {
        if (
          requiredSynchedKeys.includes(key) &&
          isOfflineId(axios_object.data[key])
        ) {
          store.commit('ui/setSnack', {
            text: 'Esta intentando guardar un registro con entidades no sincronizadas.',
            color: 'warning',
            timeout: 10000,
          })
          return {}
        }
      }
    }
    if (Object.prototype.hasOwnProperty.call(axios_object, 'params')) {
      for (const key of Object.keys(axios_object.params)) {
        if (
          requiredSynchedKeys.includes(key) &&
          isOfflineId(axios_object.params[key])
        ) {
          store.commit('ui/setSnack', {
            text: 'Esta intentando guardar un registro con entidades no sincronizadas.',
            color: 'warning',
            timeout: 10000,
          })
          return {}
        }
      }
    }
    axios_object.url = url_base + '/api' + axios_object.url
    try {
      const response = await axios(axios_object)
      return response
    } catch (error) {
      if (error.response?.status === 401) {
        store.state.ui.unauthorized_modal = true
      } else {
        store._vm.$sentry.withScope((scope) => {
          scope.setLevel('error')
          scope.setExtra('response', error.response)
          store._vm.$sentry.captureException(error)
        })
      }
      return {}
    }
  } else {
    store.commit('ui/setOnlineStatus', false)
    // store.state.ui.offline_modal = true
    return {}
  }
}
// recibe una tabla [table], la columna [column] y verifica que el valor [value] sea like %value% al valor de la columna.
// usado para filtros aproximados en los reportes
const columnLike = (table, column, value) => {
  const val = String(value).toLocaleLowerCase()
  if (typeof val === 'string') {
    table = table.filter((row) => {
      const data = String(row[column]).toLocaleLowerCase()
      if (typeof data === 'string') {
        return data.includes(val)
      } else {
        return false
      }
    })
  }
  return table
}
// recibe una tabla [table], la columna [column] y verifica que el valor [value] sea igual al valor de la columna.
// usado para filtros exactos en los reportes
const columnEquals = (table, column, value) => {
  if (value) {
    table = table.filter((row) => {
      const data = String(row[column])
      if (data) {
        return data === String(value)
      } else {
        return false
      }
    })
  }
  return table
}
// recibe una tabla [table], la columna [column] y verifica que los valores [value] existan dentro del valor de la columna
// usado para filtros compuestos en los reportes
const columnContains = (table, column, value) => {
  if (value && Array.isArray(value) && value.length > 0) {
    table = table.filter((row) => {
      const data = String(row[column])
      let found = false
      if (data) {
        for (const item of value) {
          if (data.includes(String(item))) {
            found = true
          } else {
            found = false
            break
          }
        }
        return found
      } else {
        return found
      }
    })
  }
  return table
}
// recibe la tabla [table], el encabezado [header] y el nombre del archivo [file_name]
// genera un excel a partir de un JSON
// usado para generar un excel a partir de las tablas de los reportes.
const generateExcel = (table, header, file_name) => {
  // export json to Worksheet of Excel
  // only array possible
  var headers = XLSX.utils.json_to_sheet(table)
  // A workbook is the name given to an Excel file
  var wb = XLSX.utils.book_new() // make Workbook of Excel
  // add Worksheet to Workbook
  // Workbook contains one or more worksheets
  XLSX.utils.book_append_sheet(wb, headers, header) // sheetAName is name of Worksheet
  // export Excel file
  XLSX.writeFile(wb, `${file_name}.xlsx`) // name of the file is 'book.xlsx'
}
/**
 * Transforma un objeto en un arreglo donde cada contenido de una llave se vuelve un elemento del arreglo.
 * @param {Object} object
 */
const toArray = (object) => {
  const array = []
  for (const key in object) {
    array.push(object[key])
  }
  return array
}
const producerDescription = (producer) => {
  if (typeof producer === 'object' && Object.keys(producer).length > 0) {
    return `${producer.primer_nombre} ${producer.primer_apellido} • ${producer.id}`
  }
}
const parcelaDescription = (parcela) => {
  if (typeof parcela === 'object' && Object.keys(parcela).length > 0) {
    return `Parcela • ${parcela.nombre || '(no definida)'}`
  }
}
/**
 * Verifica si la geolocalización está disponible en el navegador.
 */
const geolocationAvailable = () => {
  if (navigator.geolocation) {
    return true
  } else {
    return false
  }
}
/**
 * Obtiene la posición actual del dispositivo.
 * @returns {Promise} El objeto de la posición o nada en caso de error.
 */
const getCurrentPosition = () => {
  navigator.geolocation.getCurrentPosition(
    () => null,
    () => null,
    {}
  )
  return new Promise((resolve) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        resolve(position)
      },
      (error) => {
        // error handling
        switch (error.code) {
          case error.PERMISSION_DENIED:
            store.commit('ui/setSnack', {
              text: 'Esta aplicación no se le otorgaron permisos para acceder a la ubicación de este dispositivo.',
              timeout: 10000,
              color: 'warning',
            })
            break
          case error.POSITION_UNAVAILABLE:
            store.commit('ui/setSnack', {
              text: 'La ubicación del dispositivo no está disponible.',
              timeout: 10000,
              color: 'warning',
            })
            break
          case error.TIMEOUT:
            store.commit('ui/setSnack', {
              text: 'El dispositivo tardó demasiado en obtener la ubicación actual.',
              timeout: 10000,
              color: 'warning',
            })
            break
          case error.UNKNOWN_ERROR:
            store.commit('ui/setSnack', {
              text: 'No se pudo obtener la ubicación del dispositivo.',
              timeout: 10000,
              color: 'warning',
            })
            break
        }
      },
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 60000,
      }
    )
  })
}
const definirProductor = (productor, state) => {
  let res = {}
  // conjunto de condiciones y sus datos acertados o falladas que definen al productor
  // usado para mostrar información relevante sobre porque es familiar o no
  // de que tipo
  const c = {}
  if (productor && productor !== 'undefined') {
    res = { ...state.productores[productor] }
    res.existe = true
    // evalua el tipo de productor
    let familiar = true
    // almacena las fincas del productor
    const fincas = []
    // por cada finca verificar datos para saber si el productor es familiar o no
    let extension_tenencias = 0
    c.administrada_por_valida = true
    c.distancia_vivienda_valida = true
    for (const key in state.fincas) {
      const finca = state.fincas[key]
      if (finca.id_productor === productor) {
        fincas.push(String(key))
        // si alguna de las fincas es administrada por un administrador o el
        // productor vive a más de 50km de la finca, no es agricultor familiar
        if (finca.administrada_por === 2) {
          c.administrada_por_valida = false
          familiar = false
        }
        if (finca.distancia_vivienda >= 50) {
          c.distancia_vivienda_valida = false
          familiar = false
        }
        // calcula la suma de sus tenencias si no pertenece a una comarca indígena
        if (![10, 11, 12].includes(finca.id_provincia)) {
          for (const tenencia of finca.tenencias) {
            let tenencias = 0
            try {
              tenencias = parseFloat(tenencia.extension)
            } catch (e) {
              tenencias = 0
            }
            extension_tenencias += tenencias
          }
        }
      }
    }
    c.extension_tenencias = extension_tenencias
    // si el productor tiene empleados permanentes, no es agricultor familiar
    c.trabajador_fijo_hombre = 0
    c.trabajador_fijo_mujer = 0
    c.trabajador_eventual_hombre = 0
    c.trabajador_eventual_mujer = 0
    c.trabajadores_fijos = 0
    c.trabajadores_eventuales = 0
    if (Object.prototype.hasOwnProperty.call(state.familias, productor)) {
      const familia = state.familias[productor]
      c.trabajador_fijo_hombre = familia.trabajador_fijo_hombre
      c.trabajador_fijo_mujer = familia.trabajador_fijo_mujer
      c.trabajador_eventual_hombre = familia.trabajador_eventual_hombre
      c.trabajador_eventual_mujer = familia.trabajador_eventual_mujer
      if (
        familia.trabajador_fijo_hombre > 0 ||
        familia.trabajador_fijo_mujer > 0
      ) {
        familiar = false
      }
      c.trabajadores_fijos = c.trabajador_fijo_hombre + c.trabajador_fijo_mujer
      c.trabajadores_eventuales =
        c.trabajador_eventual_hombre + c.trabajador_eventual_mujer
    }
    let ingresos_fincas = 0
    let ingresos_fuera = 0
    let ingresos_totales = 0
    let p_ingresos_finca = 0
    const destinos_prod = []
    let produccion = 0

    // ingresos de producción acuícola
    let ingreso_acuicola = 0
    for (const key in state.acuiculturas) {
      const acuicultura = state.acuiculturas[key]
      let ingreso_anual = 0
      if (fincas.includes(String(acuicultura.id_finca))) {
        produccion += 1
        for (const produccion of acuicultura.producciones) {
          try {
            ingreso_anual = parseFloat(produccion.ingreso_anual)
          } catch (e) {
            ingreso_anual = 0
          }
          ingreso_acuicola += ingreso_anual
        }
        // almacena los destinos
        for (const destino of acuicultura.destinos) {
          destinos_prod.push(destino)
        }
      }
    }
    // ingresos de cosechas
    let ingreso_cosechas = 0
    for (const key in state.cosechas) {
      const cosecha = state.cosechas[key]
      if (fincas.includes(String(cosecha.id_finca))) {
        produccion += 1
        ingreso_cosechas = cosecha.ingreso_anual
        // almacena los destinos
        for (const destino of cosecha.destinos) {
          destinos_prod.push(destino)
        }
      }
    }
    // ingresos de agroturismo
    let ingreso_agroturismo = 0
    for (const key in state.agroturismos) {
      const agroturismo = state.agroturismos[key]
      if (fincas.includes(String(agroturismo.id_finca))) {
        // produccion += 1
        ingreso_agroturismo = agroturismo.ingreso_anual
        // almacena los destinos
        /*
                for (const destino of agroturismo.destinos) {
                  destinos_prod.push(destino)
                }
                */
      }
    }
    // ingresos de producción agroindustrial
    let ingreso_agroindustrial = 0
    for (const key in state.agroindustrias) {
      const agroindustria = state.agroindustrias[key]
      if (fincas.includes(String(agroindustria.id_finca))) {
        produccion += 1
        ingreso_agroindustrial = agroindustria.ingreso_anual
        // almacena los destinos
        for (const destino of agroindustria.destinos) {
          destinos_prod.push(destino)
        }
      }
    }
    // ingresos de producción apícola
    let ingreso_apicultura = 0
    for (const key in state.apiculturas) {
      const apicultura = state.apiculturas[key]
      let ingreso_anual = 0
      if (fincas.includes(String(apicultura.id_finca))) {
        produccion += 1
        for (const produccion of apicultura.producciones) {
          try {
            ingreso_anual = parseFloat(produccion.ingreso_anual)
          } catch (e) {
            ingreso_anual = 0
          }
          ingreso_apicultura += ingreso_anual
        }
        // almacena los destinos
        for (const destino of apicultura.destinos) {
          destinos_prod.push(destino)
        }
      }
    }
    // ingresos de producción artesanal
    let ingreso_artesanal = 0
    for (const key in state.artesanias) {
      const artesania = state.artesanias[key]
      if (fincas.includes(String(artesania.id_finca))) {
        produccion += 1
        ingreso_artesanal = artesania.ingreso_anual
        // almacena los destinos
        for (const destino of artesania.destinos) {
          destinos_prod.push(destino)
        }
      }
    }
    // ingresos de producción forestal
    let ingreso_forestal = 0
    for (const key in state.forestales) {
      const forestal = state.forestales[key]
      let ingreso_anual = 0
      if (fincas.includes(String(forestal.id_finca))) {
        produccion += 1
        for (const produccion of forestal.producciones) {
          try {
            ingreso_anual = parseFloat(produccion.ingreso_anual)
          } catch (e) {
            ingreso_anual = 0
          }
          ingreso_forestal += ingreso_anual
        }
        // almacena los destinos
        for (const destino of forestal.destinos) {
          destinos_prod.push(destino)
        }
      }
    }
    // ingresos de producción pecuaria
    let ingreso_pecuario = 0
    for (const key in state.pecuarias) {
      const pecuario = state.pecuarias[key]
      let ingreso_anual = 0
      if (fincas.includes(String(pecuario.id_finca))) {
        produccion += 1
        for (const produccion of pecuario.producciones) {
          try {
            ingreso_anual = parseFloat(produccion.ingreso_anual)
          } catch (e) {
            ingreso_anual = 0
          }
          ingreso_pecuario += ingreso_anual
        }
        // almacena los destinos
        for (const destino of pecuario.destinos) {
          destinos_prod.push(destino)
        }
      }
    }
    // ingresos de producción de pesca
    let ingreso_pesca = 0
    for (const key in state.pescas) {
      const pesca = state.pescas[key]
      let ingreso_anual = 0
      if (fincas.includes(String(pesca.id_finca))) {
        produccion += 1
        for (const produccion of pesca.producciones) {
          try {
            ingreso_anual = parseFloat(produccion.ingreso_anual)
          } catch (e) {
            ingreso_anual = 0
          }
          ingreso_pesca += ingreso_anual
        }
        // almacena los destinos
        for (const destino of pesca.destinos) {
          destinos_prod.push(destino)
        }
      }
    }
    if (Object.prototype.hasOwnProperty.call(state.familias, productor)) {
      const familia = state.familias[productor]
      for (const integrante of familia.integrantes) {
        let ingreso_fuera_finca = 0
        try {
          ingreso_fuera_finca = parseFloat(integrante.ingreso_fuera_finca)
        } catch (e) {
          ingreso_fuera_finca = 0
        }
        ingresos_fuera += ingreso_fuera_finca
      }
    }
    // suma de los ingresos de las unidades productivas
    ingresos_fincas =
      ingreso_acuicola +
      ingreso_cosechas +
      ingreso_agroturismo +
      ingreso_agroindustrial +
      ingreso_apicultura +
      ingreso_artesanal +
      ingreso_forestal +
      ingreso_pecuario +
      ingreso_pesca

    ingresos_totales = ingresos_fincas + ingresos_fuera
    if (ingresos_totales > 0) {
      p_ingresos_finca = (ingresos_fincas / ingresos_totales) * 100
      p_ingresos_finca = p_ingresos_finca.toFixed(2)
      if (p_ingresos_finca <= 50.0) {
        familiar = false
      }
    }
    if (extension_tenencias > 50) {
      familiar = false
    }
    let tipo_familiar = 1
    let porcentaje_destino = 0
    // si existe producción y es agricultor familiar
    if (familiar && produccion > 0) {
      // comercializa de 0 a 25% de lo que produce, a vecinos/comunidad
      //  – Entonces es Agricultor Familiar Tipo I
      let comercializa = destinos_prod.filter((destino) =>
        [1].includes(destino.id_destino)
      )
      let porcentajes = comercializa.map((item) => item.porcentaje)
      let sumatoria = porcentajes.reduce((acum, val) => acum + val, 0)
      porcentaje_destino = sumatoria / (produccion * 100)
      if (porcentaje_destino >= 0 && porcentaje_destino <= 0.15) {
        tipo_familiar = 1
        c.porcentaje_destino = porcentaje_destino * 100
      }
      // comercializa de 26 a 50% de lo que produce, en Feria local,
      // Feria distrital/provincial, o Intermediario - Entonces es Agricultor Familiar Tipo II
      comercializa = destinos_prod.filter((destino) =>
        [2, 3, 6].includes(destino.id_destino)
      )
      porcentajes = comercializa.map((item) => item.porcentaje)
      sumatoria = porcentajes.reduce((acum, val) => acum + val, 0)
      porcentaje_destino = sumatoria / (produccion * 100)
      if (porcentaje_destino >= 0.15 && porcentaje_destino <= 0.5) {
        tipo_familiar = 2
        c.porcentaje_destino = porcentaje_destino * 100
      }
      // Si - comercializa más de lo 50% de lo que produce, a Feria distrital/provincial,
      // Mercado nacional, Mercado institucional, Intermediario, Supermercado, o Mercado
      // de exportación - Entonces es Agricultor Familiar Tipo III
      comercializa = destinos_prod.filter((destino) =>
        [3, 4, 5, 6, 7, 8].includes(destino.id_destino)
      )
      porcentajes = comercializa.map((item) => item.porcentaje)
      sumatoria = porcentajes.reduce((acum, val) => acum + val, 0)
      porcentaje_destino = sumatoria / (produccion * 100)
      if (porcentaje_destino > 0.5) {
        tipo_familiar = 3
        c.porcentaje_destino = porcentaje_destino * 100
      }
    } else if (produccion > 0) {
      const comercializa = destinos_prod.filter(
        (destino) => ![9].includes(destino.id_destino)
      )
      const porcentajes = comercializa.map((item) => item.porcentaje)
      const sumatoria = porcentajes.reduce((acum, val) => acum + val, 0)
      porcentaje_destino = sumatoria / (produccion * 100)
      c.porcentaje_destino = porcentaje_destino * 100
    }
    c.ingresos_fincas = ingresos_fincas
    c.ingreso_acuicola = ingreso_acuicola
    c.ingreso_cosechas = ingreso_cosechas
    c.ingreso_agroturismo = ingreso_agroturismo
    c.ingreso_agroindustrial = ingreso_agroindustrial
    c.ingreso_apicultura = ingreso_apicultura
    c.ingreso_artesanal = ingreso_artesanal
    c.ingreso_forestal = ingreso_forestal
    c.ingreso_pecuario = ingreso_pecuario
    c.ingreso_pesca = ingreso_pesca
    c.ingresos_fuera = ingresos_fuera
    c.ingresos_totales = ingresos_totales
    c.p_ingresos_finca = p_ingresos_finca
    c.familiar = familiar
    c.tipo_familiar = c.familiar === true ? tipo_familiar : 0
    // asigna datos usado para condicionar al productor al objeto del productor
    res.c = c
    return res
  } else {
    c.familiar = false
    res.c = c
    res.existe = false
    res.id = ''
    // por default el productor es persona jurídica
    res.tipo_empresa = 2
    return res
  }
}

const obtenerParametroURL = (ins, param) => {
  return ins.$route.param[param] || ''
}

const clona = (object = {}) => {
  try {
    return JSON.parse(JSON.stringify(object))
  } catch (e) {
    return {}
  }
}

const clone = (object = {}) => {
  try {
    return JSON.parse(JSON.stringify(object))
  } catch (e) {
    return {}
  }
}

const edad = (fecha_de_nacimiento) => {
  const today = new Date()
  const birthDate = new Date(fecha_de_nacimiento)
  let age = today.getFullYear() - birthDate.getFullYear()
  const m = today.getMonth() - birthDate.getMonth()
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--
  }
  return age || 0
}
const mascaraDeTelefono = (id) => {
  switch (id) {
    case 'CELULAR':
    case 'TRABAJO - CELULAR':
      return '####-####'
    case 'PÚBLICO':
    case 'RESIDENCIAL':
    case 'TRABAJO - FIJO':
    case 'DEPARTAMENTO':
      return '###-####'
    default:
      return '####-####'
  }
}

const elObjetoTienePropiedades = (objeto = {}) => {
  return Object.keys(objeto).length > 0
}

const toFixed = (num, n) => {
  const reg = new RegExp('^-?\\d+(?:\\.\\d{0,' + n + '})?', 'g')
  const a = num.toString().match(reg)?.[0] ?? ''
  const dot = a.indexOf('.')
  if (dot === -1) {
    // integer, insert decimal dot and pad up zeros
    return a + '.' + '0'.repeat(n)
  }
  const b = n - (a.length - dot) + 1
  return b > 0 ? a + '0'.repeat(b) : a
}

const listRecordsKeys = (object, property, value = null) => {
  const keys = []
  if (typeof object === 'object' && object !== null) {
    if (value) {
      for (const key in object) {
        if (Array.isArray(value)) {
          value = value.map((v) => String(v))
          if (value.includes(String(object[key]?.[property]))) keys.push(key)
        } else {
          if (String(object[key]?.[property]) === String(value)) keys.push(key)
        }
      }
      return keys
    }

    return Object.keys(object)
  }

  return []
}

const cleanJSON = (json) => {
  for (let key in json) {
    if (json[key] === undefined || json[key] === null) {
      json[key] = ''
    } else if (typeof json[key] === 'object') json[key] = cleanJSON(json[key])
  }
  return json
}

export {
  toArray,
  geolocationAvailable,
  getCurrentPosition,
  url_base,
  definirProductor,
  columnLike,
  columnEquals,
  columnContains,
  generateExcel,
  http,
  rol_permitido,
  obtenerParametroURL,
  clona,
  clone,
  edad,
  mascaraDeTelefono,
  elObjetoTienePropiedades,
  producerDescription,
  parcelaDescription,
  toFixed,
  listRecordsKeys,
  sumRecordsProp,
  isProductionDateValidComparedToRecords,
  cleanJSON,
}
