import axios from 'axios'
import * as bcrypt from 'bcryptjs'
import { http } from '../utilidades'

// módulo con todo el estado relacionado a los usuarios y la autenticación de la aplicación
export default {
  namespaced: true,
  state: {
    registro: [1, 2, 5],
    reporte: [1, 3, 5],
    current_user: '',
    users: {},
  },
  mutations: {
    // agrega un usuario al listado de usuarios
    agregarUsuario(state, usuario) {
      state.current_user = usuario.email
      state.users[usuario.email] = { ...usuario }
    },
    // vacía la información relacionada al usuario actual
    cerrarSesion(state) {
      state.current_user = ''
    },
  },
  actions: {
    // verifica que las credenciales ingresadas sean correctas, de serlo agrega al usuario recibido por el backend (nombre, correo, hash) al state
    // para permitirle iniciar sesión posteriormente aunque no tenga conexión.
    async verifyCredentials(context, credenciales) {
      if (navigator.onLine) {
        try {
          const response = await http({
            url: '/login',
            method: 'post',
            data: {
              email: credenciales.email,
              password: credenciales.password,
            },
          })
          const success = response.data ? response.data.success : false
          if (success) {
            this._vm.$sentry.configureScope((scope) => {
              scope.setUser({ email: credenciales.email })
            })
            context.commit('agregarUsuario', {
              name: response.data.name,
              email: response.data.email,
              password: response.data.password, // bcrypt hashed password
              role_id: response.data.role_id,
              authorized: response.data.authorized,
              avatar: response.data.avatar,
              token: response.data.token,
              id: response.data.id,
              id_institucion: response.data.id_institucion,
              id_agencia: response.data.id_agencia,
              permissions: response.data.permissions,
            })
          }
          return success
        } catch (e) {
          if (e.response.status === 401) {
            return false
          }
        }
      } else {
        // acceso sin conexión siempre y cuando el usuario haya accedido previamente
        if (
          Object.prototype.hasOwnProperty.call(
            context.state.users,
            credenciales.email
          )
        ) {
          const user = context.state.users[credenciales.email]
          if (bcrypt.compare(credenciales.password, user.password)) {
            context.commit('agregarUsuario', user)
            return true
          } else {
            return false
          }
        } else {
          return false
        }
      }
    },
    async cerrarSesion({ commit }) {
      const response = await http({
        url: '/logout',
        method: 'get',
        params: {},
      })
      const success = response.data.success
      if (success) {
        commit('cerrarSesion')
        return success
      } else {
        return false
      }
    },
    async actualizarPassword(context, { actual, nueva, repetir_nueva }) {
      const response = await http({
        url: '/update-password',
        method: 'post',
        data: {
          actual,
          nueva,
          repetir_nueva,
        },
      })
      return response.data
    },
    async logearIMA(context, user) {
      var email = user.email
      var password = user.password
      var urlProduccion = 'https://sigap.mida.gob.pa/ima/public/'
      //var urlProduccion = 'http://localhost/ima/public/'

      axios
        .get(urlProduccion + 'validar/usuario/' + email + '/' + password)
        .then((resp) => {
          if (resp.data) {
            window.location.href = urlProduccion + 'dashboard'
          }
        })
    },
    async getUsers() {
      try {
        const users = (
          await http({
            url: '/users',
            method: 'get',
            params: {},
          })
        ).data
        return users
      } catch (error) {
        this.$sentry.captureException(error)
      }
    },
  },
  getters: {
    // retorna el usuario actual o un usuario con role -i si se encuentra vacío
    user: (state) => {
      return (
        state.users[state.current_user] || {
          name: '',
          email: '',
          password: '', // bcrypt hashed password
          role_id: [-1],
          authorized: '',
          avatar: '',
          token: '',
          id: '',
          id_institucion: '',
          id_agencia: '',
          permissions: [],
        }
      )
    },
    userCan: (state, getters) => (route) => {
      if (!Array.isArray(getters.user.permissions)) {
        return {
          browse: false,
          read: false,
          edit: false,
          add: false,
          delete: false,
        }
      }
      const b =
        getters.user.permissions
          .filter((p) => p.key === 'browse')
          .map((p) => p.table_name)
          .filter((p) => !!p)
          .filter((table) => route.includes(table)).length > 0
      const r =
        getters.user.permissions
          .filter((p) => p.key === 'read')
          .map((p) => p.table_name)
          .filter((p) => !!p)
          .filter((table) => route.includes(table)).length > 0
      const e =
        getters.user.permissions
          .filter((p) => p.key === 'edit')
          .map((p) => p.table_name)
          .filter((p) => !!p)
          .filter((table) => route.includes(table)).length > 0
      const a =
        getters.user.permissions
          .filter((p) => p.key === 'add')
          .map((p) => p.table_name)
          .filter((p) => !!p)
          .filter((table) => route.includes(table)).length > 0
      const d =
        getters.user.permissions
          .filter((p) => p.key === 'delete')
          .map((p) => p.table_name)
          .filter((p) => !!p)
          .filter((table) => route.includes(table)).length > 0

      return { browse: b, read: r, edit: e, add: a, delete: d }
    },
  },
}
